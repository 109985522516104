export default {
  userUsernameState(state) {
    return state.username;
  },
  userFirstNameState(state) {
    return state.firstName;
  },
  userLastNameState(state) {
    return state.lastName;
  },
  userFullNameState(state) {
    return `${state.firstName} ${state.lastName}`;
  },
  userEmailState(state) {
    return state.email;
  },
  userKeyState(state) {
    return state.key;
  },
  userIsLoggedInState(state) {
    return Boolean(state.key);
  },
  userIsSharedState(state) {
    return Boolean(state.isShared);
  },
  userIsStaffState(state) {
    return state.isStaff;
  },
  loginCalledDatetimeState(state) {
    return state.loginCalledDatetime;
  },
  /* have the same getters as on mcr side */
  userIsSubscribedState(state) {
    return true;
  },
  isTrialEligibleState(state) {
    return false;
  },
  standardYearlyPlanState(state) {
    return null;
  },
  subscriptionPlansState(state) {
    return [];
  },
};
